import { IonContent, IonHeader, IonPage, IonRouterLink } from '@ionic/react';
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { PageLinks } from '../common/Constants';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PaletteIcon from '@mui/icons-material/Palette';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HomeWorkIcon from '@mui/icons-material/HomeWork';

import { Helmet } from 'react-helmet';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}


function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const HelpCenter: React.FC = () => {

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <Helmet>‍
                <title>Customized Ready to install Mosquito nets with frames | Help Center</title>
                <meta name='title' content='Customized Ready to install Mosquito nets with frames' />
                <meta property='og:url' content='https://www.venetz.in/help-center' />
                <meta property='og:type' content='website' />
                <meta property='og:title' content='VENETZ | Help Center' />
                <meta property='og:description' content='VENETZ | Help Center' />
                <meta property='og:site_name' content='VENETZ' />
            </Helmet>
            <IonPage>
                {/* <Menu /> */}
                <IonHeader>
                    <Header />
                </IonHeader>
                <IonContent fullscreen id="main-content">
                    <section className="page-banner">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <span className="round-shape"></span>
                                    <h2 className="banner-title">Help Center</h2>
                                    <div className="bread-crumb"><IonRouterLink routerLink={PageLinks.HOME} className='bread-crumb-text'>Home</IonRouterLink> / Help Center</div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="history-section help_center">
                        <div className="container-fluid">
                            <div className="product_nets_tab">
                                <Box
                                    sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}
                                >
                                    <Tabs
                                        orientation="vertical"
                                        variant="scrollable"
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="Vertical tabs example"
                                        sx={{ borderRight: 1, borderColor: 'divider' }}
                                    >
                                        <Tab label="How Venetz Works?" icon={<HomeWorkIcon />}></Tab>
                                        <Tab label="How to Order ?" icon={<PaletteIcon />}></Tab>
                                        <Tab label="How to Measure ?" icon={<AspectRatioIcon />} />
                                        <Tab label="How to Install your Ready to use Mosquito nets with frames" icon={<LocationOnIcon />} />
                                    </Tabs>
                                    <TabPanel value={value} index={0}>
                                        <div className='helpcenter_tab'>
                                            <iframe src="https://www.youtube.com/embed/sJUpXic5_rA?si=I3rMKfRfbfz685fM" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <div className='helpcenter_tab'>
                                            <iframe src="https://www.youtube.com/embed/sYDXSzN-Tso?si=OXyJjl6AI5dDLI-z" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value={value} index={2}>
                                        <div className='helpcenter_tab'>
                                            <iframe src="https://www.youtube.com/embed/XqcM8nwCm7o" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value={value} index={3}>
                                        <div className='helpcenter_tab'>
                                            <iframe src="https://www.youtube.com/embed/exzGW5_8Z38?si=UUplWy5stJENOEhU" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                                        </div>
                                    </TabPanel>
                                </Box>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </IonContent>
            </IonPage>
        </>
    );
};
export default HelpCenter;
